<template>
    <apexchart type="radialBar" height="380" :options="options" :series="series.series"></apexchart>
</template>

<script>
export default {
    name: "ringChart",
    props: {
        series: {
            type: Object
        },        
        labels: {
            type: Array
        },
        colors: {
            type: Array
        },
        click: Function
    },
    data: () => ({
    }),
    computed: {
        options() {
            return {
                labels: this.labels,
                colors: this.colors,
                chart: {
                    type: 'radialBar',
                    height: 380,
                    toolbar: {
                        show: false,
                    },
                    events: {
                        dataPointSelection: this.click,
                        legendClick: this.click
                    }
                },
                legend: {
                    show: true,
                    floating: true,
                    fontSize: '12px',
                    position: 'left',
                    offsetX: 0,
                    offsetY: -4,
                    labels: {
                        useSeriesColors: true,
                    },
                    markers: {
                        size: 0
                    },
                    formatter: (seriesName, opts) => {
                        return seriesName + ":  " + this.series.values[opts.seriesIndex]
                    },
                    itemMargin: {
                        vertical: 2
                    }
                },
                responsive: [{
                    breakpoint: 1080,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetY: 0,
                        },
                        chart: {
                            height: 300,
                        },
                        grid: {
                            padding: {
                                top: -20,
                                bottom: 0
                            }
                        },  
                    }
                }],
                plotOptions: {
                    radialBar: {
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 5,
                            size: '50%',
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {
                            show: true,
                            name: {
                                show: false,
                            },
                            value: {
                                formatter: () => {
                                    return parseInt(this.series.values[1]);
                                }, 
                                fontSize: '20px',
                                fontWeight: 700,
                                color: '#F04747',
                                show: true,
                                offsetY: 3,
                            },
                            total: {
                                formatter: () => {
                                    return parseInt(this.series.values[1]);
                                }, 
                                show: true,
                                showAlways: true,
                            }
                        }
                    }
                },
                grid: {
                    padding: {
                        top: -27,
                        bottom: -15
                    }
                },
                tooltip: {
                    enabled: true,
                    custom: ({seriesIndex, w}) => {
                        return `
                            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex; background-color: ${w.config.colors[seriesIndex]};">
                                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;color:#fff">
                                    <div class="apexcharts-tooltip-y-group">
                                        <span class="apexcharts-tooltip-text-y-label">${w.config.labels[seriesIndex]}: </span>
                                        <span class="apexcharts-tooltip-text-y-value">${this.series.values[seriesIndex]}</span>
                                    </div>
                                </div>
                            </div>`;
                    }
                },
            }
        }
    },
}
</script>