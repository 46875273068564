<template>
    <apexchart type="bar" :height="height" width="100%" :options="options" :series="datasource"></apexchart>
</template>

<script>
import i18n from '@/i18n';

export default {
    name: "barChartStack",
    props: {
        datasource: {
            type: Array
        },
        height: {
            type: Number,
        },
        categories: {
            type: Array
        },
        click: Function
    },
    data: () => ({

    }),
    computed: {
        options() {
            return {
                colors: this.datasource.map(i => i.color),
                chart: {
                    type: 'bar',
                    height: this.height,
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                    stackType: '100%',
                    events: {
                        dataPointSelection: this.click,
                        legendClick: this.click
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                stroke: {
                    show: false,
                },
                dataLabels: {
                    enabled: true,
                    dropShadow: {
                        enabled: false,
                    },
                    formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                        //console.log('series', w.config.series, seriesIndex, dataPointIndex);
                        if (w.config.series[seriesIndex].value)
                            return w.config.series[seriesIndex].value[dataPointIndex]
                        else
                            return w.config.series[seriesIndex].data[dataPointIndex]
                    }
                },
                xaxis: {
                    categories: this.categories,
                    labels: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        show: true,
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    show: this.categories.length,
                },
                tooltip: {
                    enabled: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                    custom: function({/*series,*/ seriesIndex, dataPointIndex, w}) {
                        return `
                        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex; background-color: ${w.config.series[seriesIndex].color}; color: #fff;">
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group">
                                    <span class="apexcharts-tooltip-text-y-label">${w.config.series[seriesIndex].name}: </span>
                                    <span class="apexcharts-tooltip-text-y-value">${w.config.series[seriesIndex].value[dataPointIndex]}</span>
                                </div>
                            </div>
                        </div>`;
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                },
                noData: {
                    text: i18n.t('Нет_документов'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '18px',
                        fontFamily: undefined
                    }
                },
                grid: {
                    borderColor: '#fff',
                    padding: {
                        top: -28,                        
                        bottom: -5,                                               
                        left: this.categories.length ? 5 : -15,
                        right: this.categories.length ? -10 : 0
                    }
                },
            }
        }
    },
    created() {
        //console.log('barChartStack datasource', this.datasource)
    }
}
</script>