<template>
    <div class="p-relative">

        <Toolbar :isList="true" :menu="fakeMenu" v-on:toolbar-button-click="onToolbarClick" />

        <div class="wrapperForm dashboardWrapper">

            <v-row
                dense
            >

                <v-col cols="12" xs="12" sm="4" md="4" lg="2" class="section-wrapper">
                    <v-card class="mdwi-gr-green colorWdj cursor-pointer" flat @click="onInWorkStatusClick('Documents.My.OnInspect')">
                        <v-card-text
                            v-if="dsLoading"
                        >
                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="102"
                                min-height="102"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>
                        <v-card-text v-else>
                            <div class="mdwi-text-box">
                                <div class="mdwi-count">
                                    {{ ds.onInspect }}
                                    <span v-if="!!ds && ds.newOnInspect">(+{{ds.newOnInspect}})</span>
                                </div>
                            </div>
                            <div class="mdwi-title-box">{{$t('На_рассмотрении')}}</div>
                            <div class="mdwi-progress-panel">
                                <div class="mdwi-progress-bar"></div>
                            </div>
                            <div class="mdwi-description-panel">{{$t('На_рассмотрении_виджет_описание')}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4" lg="2" class="section-wrapper">
                    <v-card class="mdwi-gr-blue colorWdj cursor-pointer" flat @click="onInWorkStatusClick('Documents.My.OnExecution')">
                        <v-card-text
                            v-if="dsLoading"
                        >
                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="102"
                                min-height="102"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>
                        <v-card-text v-else>
                            <div class="mdwi-text-box">
                                <div class="mdwi-count">
                                    {{ds.onExecution}}
                                    <span v-if="!!ds && ds.newOnExecution">(+{{ds.newOnExecution}})</span>
                                </div>
                            </div>
                            <div class="mdwi-title-box">{{$t('На_исполнении')}}</div>
                            <div class="mdwi-progress-panel">
                                <div class="mdwi-progress-bar"></div>
                            </div>
                            <div class="mdwi-description-panel">{{$t('На_исполнении_виджет_описание')}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4" lg="2" class="section-wrapper">
                    <v-card class="mdwi-gr-orange colorWdj cursor-pointer" flat @click="onInWorkStatusClick('Documents.My.OnAdjustment')">
                        <v-card-text
                            v-if="dsLoading"
                        >
                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="102"
                                min-height="102"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>
                        <v-card-text v-else>
                            <div class="mdwi-text-box">
                                <div class="mdwi-count">
                                    {{ds.onAdjustment}}
                                    <span v-if="!!ds && ds.newOnAdjustment">(+{{ds.newOnAdjustment}})</span>
                                </div>
                            </div>
                            <div class="mdwi-title-box">{{$t('На_согласовании')}}</div>
                            <div class="mdwi-progress-panel">
                                <div class="mdwi-progress-bar"></div>
                            </div>
                            <div class="mdwi-description-panel">{{$t('На_согласовании_виджет_описание')}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4" lg="2" class="section-wrapper">
                    <v-card class="mdwi-gr-red colorWdj cursor-pointer" flat @click="onInWorkStatusClick('Documents.My.OnSign')">
                        <v-card-text
                            v-if="dsLoading"
                        >
                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="102"
                                min-height="102"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>
                        <v-card-text v-else>
                            <div class="mdwi-text-box">
                                <div class="mdwi-count">
                                    {{ds.onSign}}
                                    <span v-if="!!ds && ds.newOnSign">(+{{ds.newOnSign}})</span>
                                </div>
                            </div>
                            <div class="mdwi-title-box">{{$t('На_подписании')}}</div>
                            <div class="mdwi-progress-panel">
                                <div class="mdwi-progress-bar"></div>
                            </div>
                            <div class="mdwi-description-panel">{{$t('На_подписании_виджет_описание')}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- <v-col cols="12" xs="12" sm="4" md="4" lg="2" class="section-wrapper">
                    <v-card class="mdwi-gr-purple colorWdj cursor-pointer" flat @click="onInWorkStatusClick('Documents.My.OnCheck')">
                        <v-card-text>
                            <div class="mdwi-text-box">
                                <div class="mdwi-count">
                                    {{ds.onCheck}}
                                    <span v-if="!!ds && ds.newOnCheck">(+{{ds.newOnCheck}})</span>
                                </div>
                            </div>
                            <div class="mdwi-title-box">{{$t('На_проверке')}}</div>
                            <div class="mdwi-progress-panel">
                                <div class="mdwi-progress-bar"></div>
                            </div>
                            <div class="mdwi-description-panel">{{$t('На_проверке_виджет_описание')}}</div>
                        </v-card-text>
                    </v-card>
                </v-col> -->

                <v-col cols="12" xs="12" sm="4" md="4" lg="2" class="section-wrapper">
                    <v-card class="mdwi-gr-purple colorWdj cursor-pointer" flat @click="onInWorkStatusClick('Documents.My.OnInspect.Resolution')">
                        <v-card-text
                            v-if="dsLoading"
                        >
                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="102"
                                min-height="102"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>
                        <v-card-text v-else>
                            <div class="mdwi-text-box">
                                <div class="mdwi-count">
                                    {{ds.inWork}}
                                    <span v-if="!!ds && ds.newInWork">(+{{ds.newInWork}})</span>
                                </div>
                            </div>
                            <div class="mdwi-title-box">{{$t('В_работе')}}</div>
                            <div class="mdwi-progress-panel">
                                <div class="mdwi-progress-bar"></div>
                            </div>
                            <div class="mdwi-description-panel">{{$t('В_работе_виджет_описание')}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" xs="12" sm="4" md="4" lg="2" class="section-wrapper">
                    <v-card class="mdwi-gr-aqua colorWdj cursor-pointer" flat  @click="onInWorkStatusClick('Documents.My.OnRework')">
                        <v-card-text
                            v-if="dsLoading"
                        >
                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="102"
                                min-height="102"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>
                        <v-card-text v-else>
                            <div class="mdwi-text-box">
                                <div class="mdwi-count">
                                    {{ds.onRework}}
                                    <span v-if="!!ds && ds.newOnRework">(+{{ds.newOnRework}})</span>
                                </div>
                            </div>
                            <div class="mdwi-title-box">{{$t('На_доработке')}}</div>
                            <div class="mdwi-progress-panel">
                                <div class="mdwi-progress-bar"></div>
                            </div>
                            <div class="mdwi-description-panel">{{$t('На_доработке_виджет_описание')}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>

            <v-row dense>
                <v-col cols="12" sm="12" md="6" class="section-wrapper">
                    <v-card flat>

                        <v-card-text
                            v-show="docsForExecutionInfoLoading"
                        >
                            <div class="form-box-title">{{ $t("Документы_у_меня_на_исполнении") }}</div>
                            
                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="315"
                                min-height="315"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>

                        <v-card-text
                            v-show="!docsForExecutionInfoLoading"
                        >
                            <div class="form-box-title">{{ $t("Документы_у_меня_на_исполнении") }}</div>
                            <template v-if="docsForExecutionInfo">
                                <BarChartStack
                                    :datasource="getPercentageData('DocsForExecution')" 
                                    :categories="getLabels('DocsForExecution')"
                                    :click="onDocsForExecution"
                                    :height="300"
                                />
                            </template>
                        </v-card-text>

                    </v-card>
                </v-col>

                <v-col cols="12" sm="12" md="6" class="section-wrapper">
                    <v-card flat>

                        <v-card-text
                            v-show="docsForExecutionInfoLoading"
                        >
                            <div class="form-box-title">{{ $t("Исполнительская_дисциплина") }}</div>

                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="315"
                                min-height="315"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>

                        <v-card-text
                           v-show="!docsForExecutionInfoLoading"
                        >
                            <div class="form-box-title">{{ $t("Исполнительская_дисциплина") }}</div>
                            <template v-if="docsForExecutionInfo">
                                <RingChart
                                    :series="getPercentageData('PerformingDiscipline')" 
                                    :labels="getLabels('PerformingDiscipline')" 
                                    :colors="getColors('PerformingDiscipline')" 
                                    :total="getTotal('PerformingDiscipline')" 
                                    :click="onPerformingDisciplineClick"
                                />
                            </template>
                        </v-card-text>

                    </v-card>
                </v-col>

            </v-row>
            
            <v-row
                dense
            >

                <v-col cols="12" sm="12" md="6" class="section-wrapper">
                    <v-row class="widget-sub-row" dense>
                        <v-col cols="12" xs="12" sm="4" md="4" lg="4" class="section-wrapper">
                            <v-card class="cursor-pointer" flat @click="onProjectStatusClick('7')">
                                <v-card-text
                                    v-if="dsLoading"
                                >
                                    <v-skeleton-loader
                                        class="mx-auto"
                                        max-height="121"
                                        min-height="121"
                                        type="image, list-item-avatar-three-line"
                                    ></v-skeleton-loader>

                                </v-card-text>
                                <v-card-text v-else>
                                    <div class="mdwi-text-box">
                                        <div class="mdwi-count">{{ds.projectsInfo.onRework}}</div>
                                    </div>
                                    <div class="mdwi-title-box">{{$t('На_доработке')}}</div>
                                    <div class="mdwi-progress-panel">
                                        <div class="mdwi-progress-bar mdwi-gr-yellow"></div>
                                    </div>
                                    <div class="mdwi-description-panel">{{$t('На_доработке_виджет_проекты_описание')}}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" xs="12" sm="4" md="4" lg="4" class="section-wrapper">
                            <v-card class="cursor-pointer" flat @click="onProjectStatusClick('4')">
                                <v-card-text
                                    v-if="dsLoading"
                                >
                                    <v-skeleton-loader
                                        class="mx-auto"
                                        max-height="121"
                                        min-height="121"
                                        type="image, list-item-avatar-three-line"
                                    ></v-skeleton-loader>

                                </v-card-text>
                                <v-card-text v-else>
                                    <div class="mdwi-text-box">
                                        <div class="mdwi-count">{{ds.projectsInfo.onRegistration}}</div>
                                    </div>
                                    <div class="mdwi-title-box">{{$t('На_регистрации')}}</div>
                                    <div class="mdwi-progress-panel">
                                        <div class="mdwi-progress-bar mdwi-gr-blue"></div>
                                    </div>
                                    <div class="mdwi-description-panel">{{$t('На_регистрации_виджет_проекты_описание')}}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" xs="12" sm="4" md="4" lg="4" class="section-wrapper">
                            <v-card class="cursor-pointer" flat @click="onProjectStatusClick('8')">
                                <v-card-text
                                    v-if="dsLoading"
                                >
                                    <v-skeleton-loader
                                        class="mx-auto"
                                        max-height="121"
                                        min-height="121"
                                        type="image, list-item-avatar-three-line"
                                    ></v-skeleton-loader>

                                </v-card-text>
                                <v-card-text v-else>
                                    <div class="mdwi-text-box">
                                        <div class="mdwi-count">{{ds.projectsInfo.rejected}}</div>
                                    </div>
                                    <div class="mdwi-title-box">{{$t('Отклоненные')}}</div>
                                    <div class="mdwi-progress-panel">
                                        <div class="mdwi-progress-bar mdwi-gr-red"></div>
                                    </div>
                                    <div class="mdwi-description-panel">{{$t('Отклоненные_виджет_проекты_описание')}}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="section-wrapper">
                            <!--Экспериментальный виджет мероприятий-->
                            <v-card flat>

                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Мероприятия") }}</div>

                                    <Calendar :is-widget="true" />

                                </v-card-text>

                            </v-card>
                        </v-col>
                    </v-row>    

                </v-col>

                <v-col cols="12" sm="12" md="6" class="section-wrapper" v-show="!isConfigurationC5">
                    <v-card flat>

                        <v-card-text
                            v-if="outgoingRejectedInfoLoading"
                        >
                            <div class="form-box-title">{{ $t("Отклоненные_исходящие_документы") }}</div>

                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="315"
                                min-height="315"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>

                        <v-card-text
                            v-else
                        >
                            <div class="form-box-title">{{ $t("Отклоненные_исходящие_документы") }}</div>
                            <template v-if="outgoingRejectedInfo">
                               
                                <RadialBarChart
                                    :series="getPercentageData('OutgoingRejectedInfo')" 
                                    :labels="getLabels('OutgoingRejectedInfo')" 
                                    :colors="getColors('OutgoingRejectedInfo')" 
                                    :click="onMyOutgoingRejectedClick"
                                />

                            </template>
                        </v-card-text>

                    </v-card>    
                </v-col>

            </v-row>

            <v-row dense>

                <v-col cols="12" sm="12" md="6" class="section-wrapper">
                    <v-card flat>

                        <v-card-text
                            v-show="performanceInfoLoading"
                        >
                            <div class="form-box-title">{{ $t("Мое_исполнение") }}</div>

                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="315"
                                min-height="315"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>

                        <v-card-text
                            v-show="!performanceInfoLoading"
                        >
                            <div class="form-box-title">{{ $t("Мое_исполнение") }}</div>
                            <template v-if="performanceInfo">
                                <RingChart
                                    :series="getPercentageData('MyPerformance')" 
                                    :labels="getLabels('MyPerformance')" 
                                    :colors="getColors('MyPerformance')" 
                                    :total="getTotal('MyPerformance')" 
                                    :click="onMyPerformanceClick"
                                />
                            </template>
                        </v-card-text>

                    </v-card>
                </v-col>

                <v-col v-if="controlInfoLoading || controlInfo" cols="12" sm="12" md="6" class="section-wrapper">
                    <v-card flat>

                        <v-card-text
                            v-show="controlInfoLoading"
                        >
                            <div class="form-box-title">{{ $t("Мои_поручения") }}</div>

                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="315"
                                min-height="315"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>

                        <v-card-text
                            v-show="!controlInfoLoading"
                        >
                            <div class="form-box-title">{{ $t("Мои_поручения") }}</div>
                            <template v-if="controlInfo">
                                <RingChart
                                    :series="getPercentageData('MyControl')" 
                                    :labels="getLabels('MyControl')" 
                                    :colors="getColors('MyControl')" 
                                    :total="getTotal('MyControl')" 
                                    :click="onMyControlClick"
                                />
                            </template>
                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col v-if="personalControlInfoLoading || personalControlInfo" cols="12" sm="12" md="6" class="section-wrapper">
                    <v-card flat>

                        <v-card-text
                            v-show="personalControlInfoLoading"
                        >
                            <div class="form-box-title">{{ $t("Мой_личный_контроль") }}</div>

                            <v-skeleton-loader
                                class="mx-auto"
                                max-height="315"
                                min-height="315"
                                type="image, list-item-avatar-three-line"
                            ></v-skeleton-loader>

                        </v-card-text>

                        <v-card-text
                           v-show="!personalControlInfoLoading"
                        >
                            <div class="form-box-title">{{ $t("Мой_личный_контроль") }}</div>
                            <template v-if="personalControlInfo">
                                <BarChartStack
                                    :datasource="getPercentageData('MyPersonalControl')" 
                                    :categories="getLabels('MyPersonalControl')"
                                    :click="onMyPersonalControlClick"
                                    :height="300"
                                />
                            </template>
                        </v-card-text>

                    </v-card>
                </v-col>
                
            </v-row>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Toolbar from '@/components/Toolbar.vue';
import BarChartStack from '@/components/apexcharts/barChartStack';
import RingChart from '@/components/apexcharts/ringChart';
import RadialBarChart from '@/components/apexcharts/radialBarChart';
import { httpAPI } from '@/api/httpAPI';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Calendar from '@/components/Calendar';

export default {
    name: "Home",
    components: {
        Toolbar,
        BarChartStack,
        RingChart,
        RadialBarChart,
        Calendar
    },
    data: () => ({
        ds: null,
        dsLoading: true,

        docsForExecutionInfoLoading: true,
        docsForExecutionInfo: null,

        performanceInfoLoading: true,
        performanceInfo: null,

        controlInfoLoading: true,
        controlInfo: null,

        personalControlInfoLoading: true,
        personalControlInfo: null,

        outgoingRejectedInfoLoading: true,
        outgoingRejectedInfo: null
        
    }),
    computed: {
        ...mapGetters('auth', ['getUserInfo']),
                
        fakeMenu() {
            return [
                {
                    Action: "Refresh",
                    Icon: "refresh",
                    Items: null,
                    Text: i18n.t("Обновить"),
                    ToolTip: i18n.t("Обновить_форму"),
                    Type: null
                }
            ]
        },
        minPercent() {
            return 4;
        },
        fontSize() {
            return 20;
        },
        isConfigurationC5() {
            return process.env.VUE_APP_CONFIGURATION == 'C5'
        },
    },
    created() {
        this.initialize();
    },
    methods: {
        //...mapActions(['setOverlayVisible']),
        ...mapMutations('handled', { setHandledCollection: 'SET_COLLECTION'}),
        ...mapMutations('inwork', { setInWorkCollection: 'SET_COLLECTION', setInWorkStatus: 'SET_STATUS'}),
        ...mapMutations('tasks', { setTasksCollection: 'SET_COLLECTION', pushTasksStatus: 'PUSH_STATUS', pushTasksExtendedFilter: 'PUSH_EXTENDED_FILTER' }),
        ...mapMutations('projects', { setProjectsCategory: 'SET_CATEGORY'}),
        ...mapMutations('correspondence', { setCorrespondenceCollection: 'SET_COLLECTION', pushCorrespondenceStatus: 'PUSH_STATUS', pushCorrespondenceExtendedFilter: 'PUSH_EXTENDED_FILTER'}),
        ...mapActions('references', ['getWorkplaces']),
        ...mapActions('tasks', ['getExtendedFilterByCollection']),
        ...mapActions('correspondence', {getExtendedFilterByCollectionCorrespondence: 'getExtendedFilterByCollection'}),
        async initialize() {
            
            this.dsLoading = true;            
            httpAPI({
                url: '/api/actions/monitoringinfo',
                method: 'POST',
                data: JSON.stringify(this.getUserInfo.permissions),
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                    this.ds = response ? response.data.payload : null;
                    this.dsLoading = false;
            });

            this.docsForExecutionInfoLoading = true;
            httpAPI({
                url: '/api/actions/monitoring/executioninfo',
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                if (response) {
                    this.docsForExecutionInfo = response.data.payload;
                    this.docsForExecutionInfoLoading = false;
                }
                else {
                    this.docsForExecutionInfo = null;
                    this.docsForExecutionInfoLoading = false;                    
                }
            });

            this.performanceInfoLoading = true;
            httpAPI({
                url: '/api/actions/monitoring/perfomanceinfo',
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                if (response) {
                    this.performanceInfo = response.data.payload;
                    this.performanceInfoLoading = false;           
                }
                else {
                    this.performanceInfo = null;
                    this.performanceInfoLoading = false;                    
                }
            });

            this.controlInfoLoading = true;
            httpAPI({
                url: '/api/actions/monitoring/controlinfo',
                method: 'POST',
                data: JSON.stringify(this.getUserInfo.permissions),
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                if (response) {
                    this.controlInfo = response.data.payload;
                    this.controlInfoLoading = false;    
                }
                else {
                    this.controlInfo = null;
                    this.controlInfoLoading = false;                    
                }
            });

            this.personalControlInfoLoading = true;
            httpAPI({
                url: '/api/actions/monitoring/personalcontrolinfo',
                method: 'POST',
                data: JSON.stringify(this.getUserInfo.permissions),
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                if (response) {
                    this.personalControlInfo = response.data.payload;
                    this.personalControlInfoLoading = false;
                }
                else {
                    this.personalControlInfo = null;
                    this.personalControlInfoLoading = false;
                }
            });

            this.outgoingRejectedInfoLoading = true;
            httpAPI({
                url: '/api/actions/monitoring/outgoingrejectedinfo',
                method: 'POST',                
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                if (response) {                    
                    this.outgoingRejectedInfo = response.data.payload.payload;
                    this.outgoingRejectedInfoLoading = false;
                }
                else {
                    this.outgoingRejectedInfo = null;
                    this.outgoingRejectedInfoLoading = false;
                }
            });            
        },
        async onToolbarClick (event, button) {
            if (button.Action == "Refresh")
                this.initialize();
                
        },
        onInWorkStatusClick(value) {
            this.setInWorkCollection(value);
            this.$router.push({ path: "/inwork" });
        },
        onProjectStatusClick(value) {
            this.setProjectsCategory(value)
            this.$router.push({ path: "/projects" });
        },
        getLabels(mode) {
            switch (mode) {
                case "DocsForExecution":
                    return [];

                case "PerformingDiscipline":
                    return [i18n.t("Исполнено"), i18n.t("Просрочено")];

                case "MyPerformance":
                    return [i18n.t("Исполнено"), i18n.t("На_проверке_исполнения"), i18n.t("На_исполнении"), i18n.t("На_доработке"), i18n.t("Просрочено"),];

                case "MyControl":
                    return [i18n.t("Исполнено"), i18n.t("На_исполнении"), i18n.t("Просрочено")];

                case "MyPersonalControl":
                    return this.personalControlInfo.labels;

                case "OutgoingRejectedInfo":
                    return [i18n.t("Всего"), i18n.t("В_том_числе_непрочитанные")];

                default:
                    break;
            }
        },
        getColors(mode) {
            switch (mode) {
                case "DocsForExecution":
                    return [
                        '#43B581',
                        '#FAA61A',
                        '#F57731',
                        '#F04747',
                    ];

                case "PerformingDiscipline":
                    return [
                        '#43B581',
                        '#F04747',
                    ];

                case "MyPerformance":
                    return [
                        '#43B581',
                        '#7289DA',
                        '#FAA61A',
                        '#F57731',
                        '#F04747',
                    ];

                case "MyControl":
                    return [
                        '#43B581',
                        '#FAA61A',
                        '#F04747',
                    ];
                case "OutgoingRejectedInfo": 
                    return [
                        '#43B581',
                        '#F04747',
                    ];   
                default:
                    return null;
            }
        },
        getPercentageData(mode) {
            switch (mode) 
            {
                case "DocsForExecution":
                    {
                        let sourceData = [
                            this.docsForExecutionInfo.moreThan10Days,
                            this.docsForExecutionInfo.lessThan10Days,
                            this.docsForExecutionInfo.today,
                            this.docsForExecutionInfo.expired
                        ];
                        
                        if (!sourceData.reduce((a,i) => a+i ))
                            return [];

                        let coef = 100 / sourceData.map((v) => v).reduce((a, v) => a + v, 0);

                        return sourceData
                            .map((value, index) => {

                                let percentageValue = Math.round(coef * (value));
                                let correctedValue = percentageValue > 0 && percentageValue < this.minPercent ? this.minPercent : percentageValue;
                                let correctedViewValue = value == 0 ? "" : value;

                                let item = {
                                    name: '',
                                    data: [],
                                    color: '',
                                    datalabels: {
                                        formatter: function () { return correctedViewValue; },
                                        font: { size: this.fontSize }
                                    }
                                };

                                switch (index) {
                                    case 3:
                                        item.name = i18n.t("Просрочено");
                                        item.data = [0, correctedValue, 0];
                                        item.color = '#F04747';
                                        item.value = [0, this.docsForExecutionInfo.expired, 0];
                                        break;

                                    case 2:
                                        item.name = i18n.t("Срок_сегодня");
                                        item.data = [0, correctedValue, 0];
                                        item.color = '#F57731';
                                        item.value = [0, this.docsForExecutionInfo.today, 0];
                                        break;

                                    case 1:
                                        item.name = i18n.t("Осталось_менее_10_дней");
                                        item.data = [0, correctedValue, 0];
                                        item.color = '#FAA61A';
                                        item.value = [0, this.docsForExecutionInfo.lessThan10Days, 0];
                                        break;

                                    case 0:
                                        item.name = i18n.t("Осталось_более_10_дней");
                                        item.data = [0, correctedValue, 0];
                                        item.color = '#43B581';
                                        item.value = [0, this.docsForExecutionInfo.moreThan10Days, 0];
                                        break;

                                    default:
                                        break;
                                }

                                return item;
                            });
                    }

                case "PerformingDiscipline":
                    {
                        let sourceData = [
                            this.docsForExecutionInfo.executed + this.docsForExecutionInfo.notExecutedOnTime,
                            this.docsForExecutionInfo.expired
                        ];

                        let coef = 100 / (sourceData.reduce((a, b) => a + b, 0));

                        return {
                            series: sourceData.map((value) => {
                                let percentageValue = Math.round(coef * (value));
                                return percentageValue > 0 && percentageValue < this.minPercent ? this.minPercent : percentageValue;
                            }),
                            values: sourceData                        
                        };
                    }

                case "MyPerformance":
                    {
                        let sourceData = [
                            this.performanceInfo.done,
                            this.performanceInfo.answered,
                            this.performanceInfo.inWork,
                            this.performanceInfo.modify,
                            this.performanceInfo.expired];

                        let coef = 100 / (sourceData.reduce((a, b) => a + b, 0));

                        return {
                            series: sourceData.map((value) => {
                                let percentageValue = Math.round(coef * (value));
                                return percentageValue > 0 && percentageValue < this.minPercent ? this.minPercent : percentageValue;
                            }),
                            values: sourceData
                        };
                    }

                case "MyControl":
                    {
                        let sourceData = [
                            this.controlInfo.done,
                            this.controlInfo.inWork,
                            this.controlInfo.expired];

                        let coef = 100 / (sourceData.reduce((a, b) => a + b, 0));

                        return {
                            series: sourceData.map((value) => {
                                let percentageValue = Math.round(coef * (value));
                                return percentageValue > 0 && percentageValue < this.minPercent ? this.minPercent : percentageValue;
                            }),
                            values: sourceData
                        };
                    }

                case "MyPersonalControl":
                    {
                        if (!this.personalControlInfo.dataSource.flat().reduce((a, i) => a + i))
                            return [];
                       
                        let rotateClockwise = function (matrix) {
                            return matrix[0].map((val, index) => matrix.map(row => row[index]).reverse());
                        };

                        let rotateCounterClockwise = function (matrix) {
                            return matrix[0].map((val, index) => matrix.map(row => row[index])).reverse();                        
                        };

                        let sourceData = rotateClockwise(this.personalControlInfo.dataSource);
                        let coef = 100 / Math.max.apply(null, sourceData.map((data) => data.map(v => v).reduce((a, v) => a + v, 0)));

                        sourceData = rotateCounterClockwise(sourceData);

                        let percentageData = sourceData.map((data) => data.map(v => {
                            let percentageValue = Math.round(coef * (v));
                            return percentageValue > 0 && percentageValue < this.minPercent ? this.minPercent : percentageValue;
                        }));

                        let correctedData = sourceData.map((data) => data.map(v => {
                            return (v == 0 ? "" : v);
                        }));

                        return percentageData
                            .map((data, index) => {

                                let item = {
                                    name: '',
                                    data: data,
                                    color: '#',
                                    datalabels: {
                                        formatter: function (value, context){ return correctedData[context.datasetIndex][context.dataIndex]; },
                                        font: { size: this.fontSize }
                                    },
                                    value: sourceData[index]
                                };

                                switch (index) {
                                    case 0:
                                        item.name = i18n.t("Просрочено");
                                        item.color = '#F04747';
                                        break;


                                    case 1:
                                        item.name = i18n.t("Доработать");
                                        item.color = '#F57731';
                                        break;

                                    case 2:
                                        item.name = i18n.t("На_исполнении");
                                        item.color = '#FAA61A';
                                        break;

                                    case 3:
                                        item.name = i18n.t("На_проверке_исполнения");
                                        item.color = '#7289DA';
                                        break;

                                    case 4:
                                        item.name = i18n.t("Исполнено");
                                        item.color = '#43B581';
                                        break;

                                    default:
                                        break;
                                }

                                return item;
                            });
                    
                }
                
                case "OutgoingRejectedInfo":
                    {
                        let sourceData = [
                            this.outgoingRejectedInfo.total,
                            this.outgoingRejectedInfo.unread];

                        let coef = 100.0 / this.outgoingRejectedInfo.total;

                        return {
                            series: sourceData.map((value) => {
                                let percentageValue = value !== 0 ? Math.round(coef * (value)) : 0;
                                return percentageValue > 0 && percentageValue < this.minPercent ? this.minPercent : percentageValue;
                            }),
                            values: sourceData
                        };
                    }
                default:
                    break;
            }
        },
        getTotal(mode) {
            let total = "";

            switch (mode) 
            {
                case "DocsForExecution":
                    {
                        total = this.docsForExecutionInfo.total == 0 ? i18n.t("Нет_документов_на_исполнении") : this.docsForExecutionInfo.total;
                    }
                    break;

                case "PerformingDiscipline":
                    {
                        let v1 = this.docsForExecutionInfo.executed + this.docsForExecutionInfo.notExecutedOnTime * 0.5;
                        let v2 = this.docsForExecutionInfo.expired + this.docsForExecutionInfo.executed + this.docsForExecutionInfo.notExecutedOnTime;
                        total = v1 == 0 && v2 == 0 ? "--%" : Math.round((v1 / v2) * 100 || 0).toString() + "%";
                    }
                    break;

                case "MyPerformance":
                    {
                        let sourceData = [
                            this.performanceInfo.inWork,
                            this.performanceInfo.expired,
                            this.performanceInfo.answered,
                            this.performanceInfo.modify,
                            this.performanceInfo.done
                        ];

                        let sum = sourceData.reduce((a, b) => a + b, 0);
                        total = sum == 0 ? "--" : sum.toString();
                    }
                    break;

                case "MyControl":
                    {
                        let sourceData = [
                            this.controlInfo.inWork,
                            this.controlInfo.expired,
                            this.controlInfo.answered,
                            this.controlInfo.modify,
                            this.controlInfo.done
                        ];

                        let sum = sourceData.reduce((a, b) => a + b, 0);
                        total = sum == 0 ? "--" : sum.toString();
                    }
                    break;

                default:
                    break;
            }

            return total;
        },
        onDocsForExecution(event, chartContext, config) 
        {
            let statusCode;
            if(!Number.isInteger(chartContext)){
                statusCode = config.seriesIndex;
            }else{
                statusCode = chartContext;
            }
            let status = 'Any';
            switch (statusCode)
            {
                case 0: status = "MoreThan10Days"; break;
                case 1: status = "LessThan10Days"; break;
                case 2: status = "Today"; break;
                case 3: status = "Expired"; break;
                default: status = 'Any'; break;
            }

            this.setInWorkCollection("Documents.My.OnExecution");
            this.setInWorkStatus(status);
            this.$router.push({ path: "/inwork" });
        },
        async onMyPersonalControlClick(event, chartContext, config) 
        {            
            let status = "-1";

            switch (config.seriesIndex)
            {
                case 0: status = "5"; break;
                case 1: status = "4"; break;
                case 2: status = "1"; break;
                case 3: status = "2"; break;
                case 4: status = "3"; break;
                default: status = "-1"; break;
            }
            
            let executerId = this.personalControlInfo.executerIds[config.dataPointIndex];

            if (executerId) {
                let workplace = (await this.getWorkplaces())
                    .InnerExecuters.Executers
                    .find(i => i[0] == executerId);

                if (workplace) {
                    let value =     
                    {
                        id: workplace[0],
                        Value: workplace[1],
                        employeeId: workplace[2],
                        jobTitle: workplace[3]
                    }; 

                    let filter = await this.getExtendedFilterByCollection('MyControlTask');
                    filter.MyControlExecuterID.value = value

                    this.pushTasksExtendedFilter({ collection: "MyControlTask", value: filter })
                }
            }
            
            this.pushTasksStatus({ collection: "MyControlTask", value: status });
            this.setTasksCollection("MyControlTask");
            this.$router.push({ path: "/tasks" });            
        },
        onPerformingDisciplineClick(event, chartContext, config) {
            switch (config.dataPointIndex)
            {
                case 0: 
                {
                    this.setHandledCollection("Documents.My.Executed");
                    this.$router.push({ path: "/handled" });
                }
                break;

                case 1:
                {
                    this.setInWorkCollection("Documents.My.OnExecution");
                    this.setInWorkStatus("Expired");
                    this.$router.push({ path: "/inwork" });
                }
                break;
            }
        },
        onMyPerformanceClick(event, chartContext, config) {
            
            let status = "-1";
            switch (config.dataPointIndex)
            {
                case 0: status = "3"; break;
                case 1: status = "2"; break;
                case 2: status = "1"; break;
                case 3: status = "4"; break;
                case 4: status = "5"; break;
                default: status = "-1"; break;
            }

            this.pushTasksStatus({ collection: "MyExecutionTask", value: status });
            this.setTasksCollection("MyExecutionTask");
            this.$router.push({ path: "/tasks" });
        },
        onMyControlClick(event, chartContext, config) {
            
            let status = "-1";
            switch (config.dataPointIndex)
            {
                case 0: status = "3"; break;
                case 1: status = "1"; break;
                case 2: status = "5"; break;
                default: status = "-1"; break;
            }

            this.pushTasksStatus({ collection: "MyCreatedTask", value: status });
            this.setTasksCollection("MyCreatedTask");
            this.$router.push({ path: "/tasks" });
        },
        async onMyOutgoingRejectedClick() {

            let filter = await this.getExtendedFilterByCollectionCorrespondence("Outgoings");
            console.log(filter);
            filter.SendStatus.value = { id: 2,  Value: i18n.t("Отклонен") };

            this.pushCorrespondenceExtendedFilter({ collection: "Outgoings", value: filter })
            this.pushCorrespondenceStatus({ collection: "Outgoings", value: "AllOutgoings" });
            this.setCorrespondenceCollection("Outgoings");

            this.$router.push({ path: "/correspondence" });
        }
    }
}
</script>