<template>
    <apexchart type="donut" height="300" :options="options" :series="series.series"></apexchart>
</template>

<script>
export default {
    name: "ringChart",
    props: {
        series: {
            type: Object
        },        
        labels: {
            type: Array
        },
        colors: {
            type: Array
        },
        total: {
            type: String,
            default: ""
        },
        click: Function
    },
    data: () => ({
    }),
    computed: {
        options() {
            return {
                labels: this.labels,
                colors: this.colors,
                chart: {
                    type: 'donut',
                    height: 300,
                    toolbar: {
                        show: false,
                    },
                    events: {
                        //click: this.click,
                        dataPointSelection: this.click
                    }
                },
                legend: {
                    position: 'right',
                },
                responsive: [{
                    breakpoint: 1080,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetY: 0,
                        }
                    }
                }],
                stroke: {
                    show: false,
                },
                dataLabels: {
                    enabled: true,
                    dropShadow: {
                        enabled: false,
                    },
                    formatter: (val, opts) => {
                        return this.series.values[opts.seriesIndex]
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                        size: '45%',
                        labels: {
                            show: true,
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: '20px',
                                fontWeight: 700,
                                color: '#494f59',
                                formatter: (val) => {
                                    return val
                                },
                                offsetY: 5,
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                formatter: () => {
                                    return this.total;
                                }
                            }
                        }
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    // x: {
                    //     show: false,
                    // },
                    custom: ({/*series,*/ seriesIndex, w}) => {
                        //console.log(seriesIndex, w);
                        return `
                            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex; background-color: ${w.config.colors[seriesIndex]};">
                                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                    <div class="apexcharts-tooltip-y-group">
                                        <span class="apexcharts-tooltip-text-y-label">${w.config.labels[seriesIndex]}: </span>
                                        <span class="apexcharts-tooltip-text-y-value">${this.series.values[seriesIndex]}</span>
                                    </div>
                                </div>
                            </div>`;
                    }
                },
            }
        }
    },
}
</script>